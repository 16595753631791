import Vue from 'vue'
import Vuex from 'vuex'
import ChatWindow from '../components/ChatWindow'
import VuexAlong from "vuex-along";
Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        login_account: null,
        token: null,
        number: null,
        message_list: [],
        channel_list: [],
        system_messages: [],
        system_unread: 0,
        quick_answers: [],
        // message_threshold: 6 * 24 * 60 * 60 * 1000,
        member_cache: [],
        lastOrderId: 0,
        lastReceiver: '',
        loginUserName: '',
        agentAvator: '',
        userAvator: ''
    },
    actions: {
    },
    mutations: {
        setAgentList(state, dataObj) {
            dataObj.forEach((e) => {
                state.message_list.push(e)
                e.agentMessageList = []

            })
        },
        addNewAgent(state, newAgent) {
            state.message_list.unshift(newAgent);
        },
        addNewMessageByAgentNumber(state, data) {
            const index = state.message_list.findIndex(element => element.number == data.number);
            const isMessageFound = state.message_list[index].agentMessageList.find((availableMessage) => {
                return availableMessage.order_id == data.message.order_id;
            });
            //Do not add if message already exists
            if (isMessageFound == undefined) {
                state.message_list[index].agentMessageList.push(data.message);
                const tempAgentData = state.message_list[index];
                state.message_list.splice(index, 1);
                state.message_list.splice(0, 0, tempAgentData);
            }
        },
        switchPositions(state, number) {
            const index = state.message_list.findIndex(element => element.number == number);
            const tempAgentData = state.message_list[index];
            state.message_list.splice(index, 1);
            state.message_list.splice(0, 0, tempAgentData);
        },
        updateUnreadCount(state, data) {
            let currentCount = state.message_list[data.index].count;
            if (data.reset) {
                state.message_list[data.index].count = 0;
            } else {
                if (currentCount === undefined || currentCount === null){
                    currentCount = 0
                }
                const updatedCount = currentCount + 1;
                state.message_list[data.index].count = updatedCount;
            }          

        },
        clearAgentList(state) {
            state.message_list = [];
        },
        clearAllData(state) {
            // state.message_list = [];
            state.lastOrderId = "";
            state.lastReceiver = "";
            // state.loginUserName = "";
            // state.number = "";
            state.token = "";
        },
        updateAgentName(state, data) {
            state.message_list[data.index].name = data.username;
        },
        setLastUpdatedOrderId(state, data) {
            state.lastOrderId = data
        },
        setLastReceiver(state, data) {
            state.lastReceiver = data
        },
        setToken(state, dataObj) {
            state.token = dataObj;

        },
        setNumber(state, dataObj) {
            state.number = dataObj;
        },
        setLoggedUserName(state, data) {
            state.loginUserName = data;
        },
        addNewSystemMessage(state, message) {
            const isMessagePresent = state.system_messages.find(element => element.order_id == message.order_id);
            if (!isMessagePresent) {
                state.system_messages.push(message);
            }
        },
        updateSystemMessageCount(state, isClear) {
            if (isClear) {
                state.system_unread = 0;
            } else {
                const updatedCount = state.system_unread + 1;
                state.system_unread = updatedCount;
            }
        },
        updateSpecificAgentMessageList(state, data) {
            const user = state.message_list.find(element => element.number == data.userNumber);
            if (user) {
                user.agentMessageList = user.agentMessageList.concat(data.newMessages);
            }
        },
        clearAllSystemMessages(state) {
            state.system_messages = [];
            state.system_unread = 0;
        },
        markMsgAsDeleted(state, data) {
            const { agentNumber, messageOrderId } = data;
            const agentStored = state.message_list.findIndex(item => item.number == agentNumber)
            if (agentStored > -1) {
                const msg = state.message_list[agentStored].agentMessageList.findIndex(item => item.order_id == messageOrderId)
                state.message_list[agentStored].agentMessageList[msg].status = 0
            }
        },
        setAgentAvator(state, dataObj) {
            state.agentAvator = dataObj;
        },
        setUserAvator(state, dataObj) {
            state.userAvator = dataObj;
        },
        deleteSelectedCustomer(state, data){
            state.message_list.splice(data, 1);
        },
        clearAllMessages(state) {
            state.message_list = [];
        },
    },
    plugins: [
        VuexAlong({
            name: "along",
            local: { list: [], isFilter: true },
            session: false,
            justSession: false
        })
    ]
})
// store.subscribe((mutation, state) => {
//     // Store the state object as a JSON string
//     localStorage.setItem('store', JSON.stringify(state));
// });


export default store;