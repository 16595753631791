<template>
  <div>
    <b-modal
      id="modal-scrollable_trans"
      scrollable
      title="选择客服"
      :hide-footer="true"
    >
      <div
        v-for="(item, index) in agentTransferList"
        :key="index"
        style="padding: 2px 0px; cursor: pointer"
      >
        <b-list-group-item
          class="d-flex align-items-center"
          @click="doAgentTransfer(item.number)"
        >
          <b-avatar :src="item.avatar == null ? avatorImg: item.avatar"></b-avatar>
          <span class="mr-auto" style="padding-left: 30px">{{
            item.name
          }}</span>
        </b-list-group-item>
      </div>
      <!-- <b-alert
        :show="successText"
        variant="danger"
        dismissible
        @dismissed="successText = false"
        fade
        >{{ showMessage }}</b-alert
      > -->
    </b-modal>
  </div>
</template>
<script>
import eventBus from "../assets/js/eventBus";
export default {
  name: "AgentTransfer",
  props: ["selectedUser"],
  data() {
    return {
      agentTransferList: [],
      // avatorImg: require("../assets/img/chat_files/avator.png"),
      avatorImg: this.$store.state.agentAvator,
      accounts: "",
      showMessage: false,
      successText: "",
    };
  },
  methods: {
    loadAgentList() {
      
      let that = this;
      that
        .$http({
          url: `${that.$baseURL}app/Chat/agentList`,
          method: "post",
          headers: {
            
          },
          data: {},
        })
        .then((res) => {
          if (res.data.status == 200) {
            that.agentTransferList = res.data.data.filter(element=>element.number != this.$store.state.number);
          }else if(res.data.status == 401){
            eventBus.$emit("logout");
            this.$router.push({ name: "Login" });
          }
        })
        .catch((res) => {
          if (res.response.status == 401) {
            // console.log("emit logout");
            eventBus.$emit("logout");
          }
        });
    },
    doAgentTransfer(accounts) {
      let that = this;
      that
        .$http({
          url: `${that.$baseURL}app/Chat/CustomerAgent/agentTransfer`,
          method: "post",
          headers: {
            
          },
          data: {
            agent: that.$store.state.number,
            transferAgent: accounts,
            user_id: that.selectedUser.number
          },
        })
        .then((res) => {
          if (res.data.status == 200) {
            // that.showMessage = "Successfully sent";
            this.$bvModal.hide("modal-scrollable_trans");
            this.$bvModal.msgBoxOk("成功转移", {
              title: "成功",
              size: "sm",
              buttonSize: "sm",
              okVariant: "primary",
              okTitle: "确认",
              headerClass: "p-2 border-bottom-0",
              footerClass: "p-2 border-top-0",
              centered: true,
            });
          }else if(res.data.status == 401){
            eventBus.$emit("logout");
          }
        })
        .catch((res) => {
          if (res.response.status == 401) {
            // console.log("emit logout");
            eventBus.$emit("logout");
          }
        });
    },
  },
  mounted: function () {
    this.loadAgentList();
  },
};
</script>
<style scoped>
</style>

