<template>
  <div>
    <b-sidebar id="sidebar-footer_group" aria-label="Sidebar with custom footer" no-header shadow backdrop width="17%"
      v-model="groupList">
      <template #footer>
        <div class="px-3 py-2" style="background: #2c3e50">
          <b-row>
            <b-col style="display: flex; justify-content: flex-start;">
              <b-button size="sm" v-b-modal.new_group>新建群组</b-button>
            </b-col>
            <b-col style="display: flex; justify-content: flex-end;">
              <b-button size="sm" @click="hideBothWindow()">取消</b-button>
            </b-col>
          </b-row>
        </div>
      </template>
      <div class="px-3 py-2">
        <div v-if="allGroupList.length === 0">--- 当前没有群组 ---</div>

        <b-list-group v-else style="max-width: 480px">
          <b-list-group-item v-for="i in allGroupList" :key="i.id" class="d-flex align-items-center"
            @click="onRecentGroup(i)" style="cursor: pointer">
            <b-avatar v-bind:src="i.avatar == null ? $store.state.agentAvator : i.avatar" class="mr-3"></b-avatar>
            <span class="mr-auto" style="overflow: hidden;text-overflow: ellipsis;">{{ i.name }}</span>
            <!-- <b-badge v-if="i.count > 0">{{ i.count }}</b-badge> -->
          </b-list-group-item>
        </b-list-group>
      </div>
    </b-sidebar>
    <!-- ======================================== Create a New group Modal ======================================== -->
    <b-modal id="new_group" ref="modal" title="设置群聊名称" @hidden="resetModalNewGroup">
      <form ref="form" @submit.stop.prevent="handleSubmitNewGroup">
        <b-form-group label="群聊名称：" label-for="name-input" invalid-feedback="必须输入群主名" :state="nameState">
          <b-form-input id="name-input" v-model="groupname" :state="nameState" required></b-form-input>
        </b-form-group>
      </form>
      <template #modal-footer="{ cancel }">
        <b-button size="sm" @click="cancel()"> 取消 </b-button>
        <b-button size="sm" variant="primary" @click="createNewGroup()">
          确认
        </b-button>
      </template>
    </b-modal>

    <!-- ================================ Add member now or later confirmation Modal ============================================ -->

    <b-modal v-model="modalShowAddAgent" title="邀人">
      <p class="my-4">是否要邀人进群或稍后?</p>
      <template #modal-footer="{ cancel }">
        <b-button size="sm" @click="cancel()"> 稍后 </b-button>
        <b-button size="sm" variant="primary" @click="
          addMoreMembers();
        addGroupMembersModal = true;
        modalShowAddAgent = false;
        ">
          现在
        </b-button>
      </template>
    </b-modal>


    <!-- ================================ Sende member exceded message confirmation Modal ============================================ -->

    <b-modal v-model="modalShowExceededMsg" title="警告">
      <p class="my-4">该群为大群用户群，是否发送该消息？</p>
      <template #modal-footer="{ cancel }">
        <b-button size="sm" @click="cancel()"> 稍后 </b-button>
        <b-button size="sm" variant="primary" @click="
          addMaxMemberExcededMsg();
        modalShowExceededMsg = false;
        ">
          现在
        </b-button>
      </template>
    </b-modal>

    <!-- ========================================= Add member Modal ============================================ -->

    <b-modal v-model="addGroupMembersModal" scrollable size="xl">
      <template #modal-header>
        <h5>添加会员</h5>
      </template>

      <template #default>
        <b-row>
          <b-col cols="5">
            <b-form inline>
              <label class="mr-sm-4">搜索单个用户:</label>
              <b-form-input v-model="query" aria-label="First name"></b-form-input>
            </b-form>
          </b-col>
          <b-col cols="6">
            <b-form inline>
              <label class="mr-sm-4">选择代理:</label>
              <b-form-select v-model="selectedAgent" :options="allAgentList" text-field="name" value-field="number"
                style="width: 50%">
                <template #first>
                  <b-form-select-option :value="0">所有客服</b-form-select-option>
                </template>
                <!-- <b-form-select-option value="a">Option A</b-form-select-option> -->
              </b-form-select>
            </b-form>
          </b-col>
          <b-col cols="1">
            <b-button variant="primary" class="mb-2" @click="smartSearch">
              <b-icon icon="search" aria-label="Help"></b-icon>
            </b-button>
          </b-col>
        </b-row>
        <b-row><b-col cols="12"><b-form-checkbox v-model="selectAll"
              @change="selectAllMembers">选择全部</b-form-checkbox></b-col></b-row>
        <b-modal v-model="selectAllModal" centered title="确认">
          <p class="my-4">您确认添加所有成员？</p>
          <template #modal-footer="{ cancel }">
            <!-- Emulate built in modal footer ok and cancel button actions -->
            <b-button size="sm" @click="
              cancel();
            selectAll = false;
            ">
              取消
            </b-button>
            <b-button size="sm" variant="primary" @click="
              addUserToGroup('', 1, selectedAgent, query);
            selectAllModal = false;
            selectAll = false;
            addGroupMembersModal = false;
            ">
              确认
            </b-button>
          </template>
        </b-modal>
        <b-row style="max-height: 665px; overflow-y: auto; padding-top: 15px">
          <b-table-simple :current-page="currentPage" responsive>
            <b-tbody>
              <b-tr v-for="i in groupMemberList" :key="i.id">
                <b-td style="width: 23%; padding-left: 0px">
                  <b-avatar v-bind:src="i.hasOwnProperty('real_name') ? $store.state.userAvator : $store.state.agentAvator"></b-avatar></b-td>

                <b-td v-if="i.real_name" style="width: 24%; line-height: 40px">{{
                  i.real_name === "" ? "佚名" : i.real_name
                }}</b-td>
                <b-td v-else style="width: 24%; line-height: 40px">{{ i.name === "" ? "佚名" : i.name}}</b-td>
                <b-td style="width: 24%; line-height: 40px">{{
                  i.number == null || i.number == undefined ? i.user_number : i.number
                }}</b-td>
                <b-td style="width: 23%; line-height: 40px">{{
                  i.telephone != null ? i.telephone : ''
                }}</b-td>
                <b-td style="text-right; padding-right: 25px; padding-top: 15px;"><b-form-checkbox v-model="i.selected"
                    @change="onUserAddListCheckbox(i)"></b-form-checkbox></b-td>
              </b-tr>
              <b-tr v-if="groupMemberList.length == 0"><b-td>
                  <p style="text-align: center; margin: 30px">无结果</p>
                </b-td></b-tr>
            </b-tbody>
          </b-table-simple>
          <!-- <b-pagination v-if="total_rows > 1" style="position: absolute; bottom: -60px; width: 200px"
            v-model="currentPage" :total-rows="total_rows" aria-controls="member_table" per-page="10"
            @change="pageChangeSmartSearch"></b-pagination> -->
          <b-pagination v-if="total_rows > 1"
            v-model="currentPage" :total-rows="total_rows" aria-controls="member_table" per-page="10"
            @change="pageChangeSmartSearch"></b-pagination>
        </b-row>
      </template>

      <template #modal-footer="{ cancel, ok }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" @click="cancel()"> 取消 </b-button>
        <b-button size="sm" variant="primary" @click="ok()"> 确认 </b-button>
      </template>
    </b-modal>

    <!-- ======================================================================================================== -->
    <!-- ============================================= Chat panel of group chat ================================= -->

    <b-sidebar right shadow width="83%" no-header v-model="groupChatWindow">
      <b-overlay :show="isShowLoader" no-wrap style="z-index: 20000"></b-overlay>
      <b-col col lg="12" class="pannelBackground">
        <b-row style="background: #435f7a; height: 50px">
          <b-col col lg="6">
            <p class="chatGroupname">{{ selectedGroup.name }}</p>
          </b-col>
          <b-col cols="6" style="padding-top: 5px">
            <b-button-toolbar style="float: right">
              <b-button-group class="mr-1">
                <!-- ============================ Buttons for show group members, add or remove group members ============================== -->

                <b-button @click="showGroupMembers()" style="background: #2c3e50">
                  <b-icon icon="person-lines-fill" aria-hidden="true" style="line-height: 50px; color: #ffffff"></b-icon>
                </b-button>

                <!-- ============================ Buttons for add or remove members ============================== -->

                <b-button @click="addMoreMembers()" style="background: #2c3e50">
                  <b-icon icon="person-plus" aria-hidden="true" style="line-height: 50px; color: #ffffff"></b-icon>
                </b-button>

                <!-- ============================ Buttons for rename group ============================== -->

                <b-button v-b-modal.rename_group style="background: #2c3e50">
                  <b-icon icon="pencil-square" aria-hidden="true" style="line-height: 50px; color: #ffffff"></b-icon>
                </b-button>

                <!-- ============================ Buttons for aload history ============================== -->

                <b-button @click="
                  keyWordHistory = '';
                loadGroupHistory();
                " style="background: #2c3e50">
                  <b-icon icon="clock-history" aria-hidden="true" style="line-height: 50px; color: #ffffff"></b-icon>
                </b-button>

                <!-- ============================ Buttons for delete a group ============================== -->

                <b-button v-b-modal.deleteGroup style="background: #2c3e50">
                  <b-icon icon="trash" aria-hidden="true" style="line-height: 50px; color: #ffffff"></b-icon>
                </b-button></b-button-group>
            </b-button-toolbar>

            <!-- =================================== show member list Modal ======================== -->

            <b-modal v-model="showGroupMembersModal" size="xl">
              <template #modal-header>
                <h5>会员列表</h5>
              </template>

              <template #default>
                <b-row>
                  <b-col cols="6">
                    <b-form inline>
                      <label class="mr-sm-4">搜索用户:</label>
                      <b-form-input aria-label="user" v-model="memberListQuery"></b-form-input>
                      <b-button @click="onMemberListSearch" variant="primary" style="margin-left: 10px">
                        <b-icon icon="search" aria-label="username"></b-icon>
                      </b-button>
                    </b-form>
                  </b-col>
                  <b-col cols="6" style="text-align: right">
                    <div style="padding-right: 15px">
                      <b-button variant="primary" v-b-modal.modalRemoveAll>移除所有</b-button>
                    </div>
                  </b-col>
                  <b-modal id="modalRemoveAll" centered title="确认">
                    <p>您确认移除所有成员？</p>
                    <template #modal-footer="{ cancel }">
                      <b-button size="sm" @click="cancel()"> 取消 </b-button>
                      <b-button size="sm" variant="primary" @click="removeAllGroupMembers()">
                        确认
                      </b-button>
                    </template>
                  </b-modal>
                </b-row>
                <b-row style="height: 700px; overflow-y: auto; padding-top: 15px">
                  <b-table-simple id="member_table" :current-page="currentPage" responsive>
                    <b-tbody>
                      <b-tr v-for="i in allGroupMembers" :key="i.id">
                        <b-td style="width: 23%; padding-left: 0px">
                          <b-avatar
                            v-bind:src="i.hasOwnProperty('real_name') ? $store.state.userAvator : $store.state.agentAvator"></b-avatar></b-td>
                        <b-td style="width: 24%; line-height: 40px">{{
                          i.real_name === "" ? "佚名" : i.real_name
                        }}</b-td>
                        <b-td style="width: 24%; line-height: 40px">{{
                          i.user_number
                        }}</b-td>
                        <b-td style="width: 19%; line-height: 40px">{{
                          i.telephone
                        }}</b-td>
                        <b-td style="text-right; padding-top: 15px; display: flex; justify-content: flex-end; padding-right: 15px;">
                          <!-- <b-form-checkbox
                            v-model="i.selected" @change="onUserAddListCheckbox(i)"></b-form-checkbox> -->
                            <b-button size="sm" variant="primary" @click="onUserAddListCheckbox(i)">移除</b-button>
                        </b-td>
                      </b-tr>
                      <b-overlay :show="isShowLoaderPagination" no-wrap style="z-index: 20000"></b-overlay>
                    </b-tbody>
                  </b-table-simple>
                  <p v-if="allGroupMembers.length == 0" style="text-align: center">
                    无结果
                  </p>
                  <b-pagination style="position: absolute; bottom: -60px; width: 200px" v-model="currentPage"
                    :total-rows="total_rows" per-page="10" aria-controls="member_table"
                    @change="pageChange"></b-pagination>
                </b-row>
              </template>

              <template #modal-footer="{ cancel, ok }">
                <!-- Emulate built in modal footer ok and cancel button actions -->
                <b-button size="sm" @click="cancel()"> 取消 </b-button>
                <b-button size="sm" variant="primary" @click="ok()">
                  确认
                </b-button>
              </template>
            </b-modal>

            <!-- ================================= show history Modal ================================== -->

            <b-modal v-model="groupHistoryModal" scrollable size="xl">
              <template #modal-header>
                <h5>聊天记录</h5>
              </template>

              <template #default>
                <b-row>
                  <b-col cols="7">
                    <b-form inline>
                      <label class="mr-sm-4">查找聊天记录:</label>
                      <b-form-input aria-label="history" v-model="keyWordHistory"></b-form-input>
                      <!-- -------------------------------- search by key word, same API ------------------------ -->
                      <b-button variant="primary" style="margin-left: 10px" @click="loadGroupHistory(keyWordHistory)">
                        <b-icon icon="search" aria-label="message"></b-icon>
                      </b-button>
                    </b-form>
                  </b-col>
                  <b-col cols="5"> </b-col>
                </b-row>
                <b-row>
                  <div class="chatWindowBodyHistory" style="max-height: 700px; overflow-y: auto">
                    <ul class="chatWindowBodyUl">
                      <!-- ---------------------------------- history message UI section ------------------------------------>
                      <li v-for="(item, index) in historyDataGroup" :key="index">
                        <div class="sent">
                          <img class="imageright"
                            :src="$store.state.agentAvator" />
                          <div class="talktext">
                            <p v-if="item.type == 0" v-html="processContent(
                              item.message.replace(
                                /(?:\r\n|\r|\n)/g,
                                '<br />'
                              )
                            )
                              "></p>
                            <b-img v-if="item.type == 1" :src="item.message" @click="
                              showImg(
                                item.message
                              )" style="width: auto;
                                height: 200px;
                                max-width: 600px;
                              "></b-img>
                            <!-- ================================= voice record messages ========================= -->
                            <audio controls v-if="item.type == 2" :src="$baseStoragePath + JSON.parse(item.content).file
                              " type="audio/mpeg"></audio>
                            <b-embed v-if="item.type == 8" type="video" aspect="4by3" controls>
                              <source :src="item.message" type="video/mp4" />
                            </b-embed>
                            <div v-if="item.type == 3" @click="onFileGroup(item.message)">
                              <img v-bind:src="getFileTypeGroup(item.message)" style="width: auto; height: 100px" />
                              <p style="
                                  text-align: right;
                                  color: #ced4da;
                                  font-size: 13px;
                                ">
                                <!-- {{ JSON.parse(item.content).name }} -->
                              </p>
                            </div>
                            <p style="
                                text-align: right;
                                color: #ced4da;
                                font-size: 13px;
                              ">
                              {{ getTime(item.datetime) }}
                            </p>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <p v-if="historyDataGroup.length == 0" style="text-align: center; margin-top: 100px">
                      无结果
                    </p>
                    <div class="chatHistory" id="groupChatLog" v-if="loadMoreGroupBtn">
                      <b-button style="background: #2c3e50" @click="loadGroupHistory()">
                        查看更多记录
                      </b-button>
                    </div>
                  </div>
                </b-row>
              </template>

              <template #modal-footer="{ cancel, ok }">
                <b-button size="sm" @click="cancel()"> 取消 </b-button>
                <b-button size="sm" variant="primary" @click="ok()">
                  确认
                </b-button>
              </template>
            </b-modal>

            <!-- ====================================== Rename group name Modal ====================================== -->

            <b-modal id="rename_group" title="更新群名" @hidden="resetModalNewGroupName">
              <form ref="form" @submit.stop.prevent="handleSubmitNewGroupName">
                <b-form-group label="新的群名:" label-for="name-input" invalid-feedback="必须输入群主名" :state="nameStateGroup">
                  <b-form-input id="name-input" v-model="renamegroupname" :state="nameStateGroup" required></b-form-input>
                </b-form-group>
              </form>
              <template #modal-footer="{ cancel }">
                <b-button size="sm" @click="cancel()"> 取消 </b-button>
                <b-button size="sm" variant="primary" @click="handleOkRenameGroupName()">
                  确认
                </b-button>
              </template>
            </b-modal>
            <!-- =========================================== Delete Group Modal ==================================== -->

            <b-modal id="deleteGroup" title="删除群组" centered>
              <p class="my-4">确定删除 {{ selectedGroup.name }}?</p>
              <template #modal-footer="{ cancel }">
                <b-button size="sm" @click="cancel()"> 取消 </b-button>
                <b-button size="sm" variant="primary" @click="deletedGroup()">
                  确认
                </b-button>
              </template>
            </b-modal>
          </b-col>
        </b-row>
        <!-- =============================== Send message with emoji, image, file, video ======================= -->

        <b-row>
          <b-col>
            <div style="padding: 190px 190px 0px">
              <b-form-textarea id="textarea-small" size="sm" placeholder="请输入内容" style="height: 500px"
                v-model="groupMessageContent"></b-form-textarea>
            </div>
            <b-row style="padding: 25px 190px 0px">
              <!-- <b-col cols="10"></b-col> -->
              <b-col cols="8" style="text-align: left">
                <b-button-group>
                  <b-button id="showPopupEmoji" style="background: #2c3e50">
                    <b-icon icon="emoji-smile"></b-icon>
                  </b-button>
                  <b-button style="background: #2c3e50">
                    <input id="fileUploadImageGroup" type="file" hidden accept="image/*" @change="onImageUploadGroup" />
                    <b-icon icon="card-image" @click="chooseImageGroup()"></b-icon>
                  </b-button>
                  <b-button style="background: #2c3e50">
                    <input id="fileUploadVideoGroup" type="file" accept="video/*" hidden @change="onVideoUploadGroup" />
                    <b-icon icon="camera-video" @click="chooseVideoGroup()"></b-icon>
                  </b-button>
                  <b-button style="background: #2c3e50">
                    <input id="fileUploadFileGroup" type="file" accept="file/*" hidden @change="onFileUploadGroup" />
                    <b-icon icon="folder" @click="chooseFileGroup()"></b-icon>
                  </b-button>
                </b-button-group>
                <!-- ========= emoji popup ========= -->
                <b-popover target="showPopupEmoji" placement="topright" :show.sync="emojiPopupShow">
                  <ul class="emojiDesign">
                    <li class="emojiListDesign" v-for="(item, index) in emojiIcons" :key="index" @click="onEmoji(item)">
                      <b-img :src="require(`../assets/img/emoji_files/${item.id}.png`)" />
                    </li>
                  </ul>
                </b-popover>
              </b-col>
              <b-col cols="4" style="text-align: right">
                <b-button @click="sendMessageGroup(0, groupMessageContent)" style="background: #2c3e50">
                  发送 <b-icon icon="chevron-right"></b-icon> </b-button></b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-sidebar>
  </div>
</template>
<script>
import Vue from "vue";
import moment from "moment";
import hevueImgPreview from "hevue-img-preview";
import eventBus from "../assets/js/eventBus";
Vue.use(hevueImgPreview);

export default {
  name: "Groups",
  data() {
    return {
      groupname: "",
      nameState: null,
      modalShowAddAgent: false,
      addGroupMembersModal: false,
      selectedAgent: 0,
      showPopupEmoji: false,
      emojiPopupShow: false,
      isShowLoader: false,
      isShowLoaderPagination: false,
      errorText: "",
      showError: false,
      modalShowExceededMsg: false,
      content: "",
      format: "",

      emojiIcons: [
        {
          id: 0,
          text: "../assets/img/emoji_files/0.png",
          key: "[微笑]",
        },
        {
          id: 1,
          text: this.$baseURL + "../assets/img/emoji_files/1.png",
          key: "[爱你]",
        },
        {
          id: 2,
          text: this.$baseURL + "../assets/img/emoji_files/2.png",
          key: "[拜拜]",
        },
        {
          id: 3,
          text: this.$baseURL + "../assets/img/emoji_files/3.png",
          key: "[悲伤]",
        },
        {
          id: 4,
          text: this.$baseURL + "../assets/img/emoji_files/4.png",
          key: "[鄙视]",
        },
        {
          id: 5,
          text: this.$baseURL + "../assets/img/emoji_files/5.png",
          key: "[闭嘴]",
        },
        {
          id: 6,
          text: this.$baseURL + "../assets/img/emoji_files/6.png",
          key: "[馋嘴]",
        },
        {
          id: 7,
          text: this.$baseURL + "../assets/img/emoji_files/7.png",
          key: "[吃惊]",
        },
        {
          id: 8,
          text: this.$baseURL + "../assets/img/emoji_files/8.png",
          key: "[鼓掌]",
        },
        {
          id: 9,
          text: this.$baseURL + "../assets/img/emoji_files/9.png",
          key: "[哈哈]",
        },
        {
          id: 10,
          text: this.$baseURL + "../assets/img/emoji_files/10.png",
          key: "[害羞]",
        },
        {
          id: 11,
          text: this.$baseURL + "../assets/img/emoji_files/11.png",
          key: "[黑线]",
        },
        {
          id: 12,
          text: this.$baseURL + "../assets/img/emoji_files/12.png",
          key: "[可爱]",
        },
        {
          id: 13,
          text: this.$baseURL + "../assets/img/emoji_files/13.png",
          key: "[可怜]",
        },
        {
          id: 14,
          text: this.$baseURL + "../assets/img/emoji_files/14.png",
          key: "[亲亲]",
        },
        {
          id: 15,
          text: this.$baseURL + "../assets/img/emoji_files/15.png",
          key: "[生病]",
        },
        {
          id: 16,
          text: this.$baseURL + "../assets/img/emoji_files/16.png",
          key: "[偷笑]",
        },
        {
          id: 17,
          text: this.$baseURL + "../assets/img/emoji_files/17.png",
          key: "[挖鼻]",
        },
        {
          id: 18,
          text: this.$baseURL + "../assets/img/emoji_files/18.png",
          key: "[委屈]",
        },
      ],
      groupList: false,
      groupChatWindow: false,
      renamegroupname: "",
      nameStateGroup: null,
      allGroupList: "",
      selectedGroup: { name: "" },
      allAgentList: [],
      showGroupMembersModal: false,
      groupHistoryModal: false,
      allGroupMembers: "",
      // avatorImg: require("../assets/img/chat_files/avator.png"),
      avatorImg: this.$store.state.agentAvator,
      userAvatarImg: this.$store.state.userAvator,
      // history
      loadMoreGroupBtn: false,
      historyPageNum: 1,
      historyDataGroup: [],
      historyDataTempGroup: [],
      keyWordHistory: "",

      //add memebers
      query: "",
      showMyUser: false,
      groupMemberList: [],
      selectAll: false,
      selectAllModal: false,
      memberListQuery: "",
      //table pagination
      currentPage: 1,
      total_rows: 0,

      //send message
      groupMessageContent: "",
    };
  },
  methods: {
    onRecentGroup(item) {
      this.groupChatWindow = true;
      this.selectedGroup = item;
      //set unread count to 0;
      item.count = 0;
      //reset paginator
      this.currentPage = 1;
      this.total_rows = 0;
    },

    // ============== Hide both windows =================

    hideBothWindow() {
      this.groupList = false;
      this.groupChatWindow = false;
    },

    // ===================  Hide emoji popup ======
    onEmoji(emoji) {
      this.showPopupEmoji = false;
      this.groupMessageContent += "face" + emoji.key;
      this.onCloseEmojiPopup();
    },

    onCloseEmojiPopup() {
      this.emojiPopupShow = false;
    },

    // =============== Add more members ========
    addMoreMembers() {
      this.groupMemberList = [];
      this.total_rows = 0;
      this.addGroupMembers();
      this.loadMyTeam();
    },

    // ================================================= Creat a group ================================================
    resetModalNewGroup() {
      this.groupname = "";
      this.nameState = null;
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      return valid;
    },
    createNewGroup() {
      let that = this;
      if (!this.checkFormValidity()) {
        return;
      }
      that
        .$http({
          url: `${that.$baseURL}/app/GroupChat/createGroup`,
          method: "post",
          headers: {

          },
          data: {
            founder: that.$store.state.number,
            name: that.groupname,
          },
        })
        .then((res) => {
          let data = res.data;
          if (data.status == 200) {
            that.loadAllGroups();

            that.selectedGroup = data.data;

            this.$nextTick(() => {
              this.$bvModal.hide("new_group");
              this.modalShowAddAgent = true;
            });
          } else if (data.status == 401) {
            this.$router.push({ name: "Login" });
          }
           else {
            this.$bvModal.hide("new_group");
            this.$bvModal.msgBoxOk("名称已存在", {
              size: "sm",
              buttonSize: "sm",
              okVariant: "danger",
              okTitle: "确认",
              headerClass: "p-2 border-bottom-0",
              footerClass: "p-2 border-top-0",
              centered: true,
            });
           }
        }).catch((e)=>{
          console.error(e.message);
        });
    },
    //============================== load all group list ================
    loadAllGroups() {
      let that = this;
      that
        .$http({
          url: `${that.$baseURL}app/GroupChat/getGroupList`,
          method: "post",
          headers: {

          },
          data: {
            founder: that.$store.state.number
          },
        })
        .then((res) => {
          // console.log(res);
          if (res.data.status == 200) {
            that.allGroupList = res.data.data;
          } else if (res.data.status == 401) {
            this.$router.push({ name: "Login" });
          }
        }).catch((e)=>{
          console.error(e.message);
        });
    },

    /**
     * load my team
     */
    loadMyTeam(searchCriteria = '') {
      let that = this;
      that
        .$http({
          url: `${that.$baseURL}app/GroupChat/getNotGroupUsers`,
          method: "post",
          headers: {

          },
          data: {
            key: searchCriteria,
            groupId: that.selectedGroup.id,
            page: that.currentPage,
            limit: 10,
            agent: that.$store.state.number
          },
        })
        .then((res) => {

          if (res.data.status == 200) {
            res.data.data.records.forEach((i) => {
              that.groupMemberList.push(i);
            });
            that.total_rows = res.data.data.total;
          } else if (res.data.status == 401) {
            eventBus.$emit("logout");
            this.$router.push({ name: "Login" });
          }
        }).catch((e)=>{
          console.error(e.message);
        });
    },
    // ======================= all group member modal functionality ===========

    showGroupMembers(isInitial = true) {
      
      // this.showGroupMembersModal = true;

      //this.selectedGroup.id
      //(that.currentPage - 1)

      let that = this;
      this.isShowLoaderPagination = true;
      this.isShowLoader = true;
      that
        .$http({
          url: `${that.$baseURL}app/GroupChat/groupMembers`,
          method: "post",
          headers: {

          },
          data: {
            groupId: that.selectedGroup.id,
            account: isInitial ? '' : that.memberListQuery,
            page: that.currentPage,
            limit: 10
          },
        })
        .then((res) => {
          // console.log(res);
          if (res.data.status == 200) {
            this.isShowLoaderPagination = false;
            this.isShowLoader = false;
            const allRecords = res.data.data.records;
            that.allGroupMembers = allRecords;
            that.allGroupMembers.forEach((i) => {
              i.selected = true;
            });
            that.showGroupMembersModal = true;
            that.total_rows = res.data.data.total;

          } else if (res.data.status == 401) {
            eventBus.$emit("logout");
            this.$router.push({ name: "Login" });
          } else {
            this.isShowLoaderPagination = false;
            this.isShowLoader = false;
          }
        }).catch((e)=>{
          console.error(e.message);
        })
        .finally(() => {
          this.isShowLoader = false;
          this.isShowLoaderPagination = false;
        });
    },

    // ======================= add group member modal functionality - get agent list===========

    addGroupMembers() {
      let that = this;
      this.isShowLoader = true;
      that
        .$http({
          url: `${that.$baseURL}app/Chat/agentList`,
          method: "post",
          headers: {

          },
          data: {},
        })
        .then((res) => {
          this.isShowLoader = false;
          if (res.data.status == 200) {
            // this.modalShowAddAgent = false;
            this.addGroupMembersModal = true;
            this.selectAll = false;
            res.data.data.forEach((agent) => {
              agent.selected = false;
              that.allAgentList.push(agent);
              if (agent.number != that.$store.state.number) {
                that.groupMemberList.push(agent);
              }
            })

            // that.groupMemberList = that.allAgentList;
          } else if (res.data.status == 401) {
            this.$router.push({ name: "Login" });
          }
        }).catch((e)=>{
          console.error(e.message);
        })
        .finally(() => {
          this.isShowLoader = false;
        });
    },
    /**
     * add members to group
     */
    addMemeberToGroup(member) {
      //TODO add group memebers from api
    },
    /**
     * smart search
     */
    smartSearch() {

      // "api/user/smartSearch?groupId=" +
      //       that.selectedGroup.id +
      //       "&agentId=" +
      //       that.selectedAgent +
      //       "&showMyUsers=" +
      //       that.showMyUser +
      //       "&currentPage=" +
      //       (that.currentPage - 1) +
      //       "&keyWord=" +
      //       that.query,

      let that = this;
      that
        .$http({
          url: `${that.$baseURL}app/GroupChat/getNotGroupUsers`,
          method: "post",
          headers: {

          },
          data: {
            groupId: that.selectedGroup.id,
            key: that.query,
            limit: 10,
            page: that.currentPage,
            agent: that.selectedAgent == 0 ? '' : that.selectedAgent
          },
        })
        .then((res) => {
          let data = res.data;
          if (data.status == 200) {
            // this.addGroupMembersModal = true;
            // this.modalShowAddAgent = false;
            that.groupMemberList = data.data.records;

            that.total_rows = data.data.total;
            // console.log(data.dataList);
          } else if (res.data.status == 401) {
            eventBus.$emit("logout");
            this.$router.push({ name: "Login" });
          }
        }).catch((e)=>{
          console.error(e.message);
        });
    },
    // ======================= delete group functionality ===========

    deletedGroup() {
      let that = this;
      that
        .$http({
          url: `${that.$baseURL}app/GroupChat/delete_group`,
          method: "post",
          headers: {

          },
          data: {
            id: that.selectedGroup.id
          },
        })
        .then((res) => {
          let data = res.data;
          if (data.status == 200) {
            that.loadAllGroups();
            this.groupChatWindow = false;
            this.$bvModal.msgBoxOk("成功移除群组", {
              title: "成功",
              size: "sm",
              buttonSize: "sm",
              okVariant: "primary",
              okTitle: "确认",
              headerClass: "p-2 border-bottom-0",
              footerClass: "p-2 border-top-0",
              centered: true,
            });
            this.$bvModal.hide("deleteGroup");
          } else if (data.status == 401) {
            this.$router.push({ name: "Login" });
          }
        }).catch((e)=>{
          console.error(e.message);
        });
    },
    // ===========  Rename group name
    checkFormValidityName() {
      const valid = this.$refs.form.checkValidity();
      this.nameStateGroup = valid;
      return valid;
    },

    resetModalNewGroupName() {
      this.renamegroupname = "";
      this.nameStateGroup = null;
    },

    handleOkRenameGroupName() {
      let that = this;
      if (!this.checkFormValidityName()) {
        return;
      }
      that
        .$http({
          url: `${that.$baseURL}app/GroupChat/groupRename`,
          method: "post",
          headers: {

          },
          data: {
            id: that.selectedGroup.id,
            founder: that.$store.state.number,
            name: that.renamegroupname.trim(),
          },
        })
        .then((res) => {
          let data = res.data;
          if (data.status == 200) {
            that.loadAllGroups();
            that.selectedGroup.name = that.renamegroupname;
            this.$bvModal.msgBoxOk("成功更改群组名称", {
              title: "成功",
              size: "sm",
              buttonSize: "sm",
              okVariant: "primary",
              okTitle: "确认",
              headerClass: "p-2 border-bottom-0",
              footerClass: "p-2 border-top-0",
              centered: true,
            });
            this.$bvModal.hide("rename_group");
          } else if (data.status == 401) {
            this.$router.push({ name: "Login" });
          } else {
            this.$bvModal.hide("rename_group");
            this.$bvModal.msgBoxOk("名称已存在", {
              size: "sm",
              buttonSize: "sm",
              okVariant: "danger",
              okTitle: "确认",
              headerClass: "p-2 border-bottom-0",
              footerClass: "p-2 border-top-0",
              centered: true,
            });
          }
        }).catch((e)=>{
          console.error(e.message);
        });
    },
    // ===============================================================================================
    // ============================================= History =========================================
    // ===============================================================================================
    loadGroupHistory(searchCriteria = '') {
      // this.groupHistoryModal = true;
      let that = this;
      let elmentLoadMoreGroup = document.getElementById("groupChatLog");
      if (!elmentLoadMoreGroup) {
        that.historyPageNum = 1;
      } else {
        that.historyPageNum++;
      }
      this.isShowLoader = true;
      that
        .$http({
          url: `${that.$baseURL}app/GroupChat/getGroupChatHistory`,
          method: "post",
          headers: {

          },
          data: {
            page: that.historyPageNum,
            limit: 20,
            groupId: this.selectedGroup.id,
            message: searchCriteria
          },
        })
        .then((res) => {
          if (res.data.status == 200) {
            this.isShowLoader = false;
            this.groupHistoryModal = true;

            that.historyDataTempGroup = res.data.data.records;

            if (that.historyPageNum != 1) {
              that.historyDataGroup = that.historyDataGroup.concat(
                that.historyDataTempGroup
              );
            } else {
              this.isShowLoader = false;
              that.historyDataGroup = that.historyDataTempGroup;
            }
            //  Hide/show loadmore button
            if (that.historyDataTempGroup.length >= 20) {
              that.loadMoreGroupBtn = true;
            } else {
              that.loadMoreGroupBtn = false;
            }
          } else if (res.data.status == 401) {
            eventBus.$emit("logout");
            this.$router.push({ name: "Login" });
          }
        }).catch((e)=>{
          console.error(e.message);
        })
        .finally(() => {
          this.isShowLoader = false;
        });
    },
    getTime(timestamp) {
      return moment(timestamp).format("MMMM Do YYYY, h:mm:ss a");
    },
    getFileTypeGroup(content) {
      let fileNameArray = content.split(".");
      let fileType = fileNameArray[fileNameArray.length - 1];
      switch (fileType) {
        case "pdf":
          return require("../assets/img/chat_files/pdf.png");
        case "doc":
          return require("../assets/img/chat_files/doc.png");
        case "docx":
          return require("../assets/img/chat_files/doc.png");
        case "ppt":
          return require("../assets/img/chat_files/ppt.png");
        case "xls":
          return require("../assets/img/chat_files/xls.png");
        case "xlsx":
          return require("../assets/img/chat_files/xls.png");
        case "jpeg":
          return require("../assets/img/chat_files/jpeg.png");
        case "jpg":
          return require("../assets/img/chat_files/jpeg.png");
        case "mp3":
          return require("../assets/img/chat_files/mp3.png");
        case "png":
          return require("../assets/img/chat_files/png.png");
        case "rar":
          return require("../assets/img/chat_files/rar.png");
        case "txt":
          return require("../assets/img/chat_files/txt.png");
        case "zip":
          return require("../assets/img/chat_files/zip.png");
        case "gif":
          return require("../assets/img/chat_files/gif.png");
        default:
          return require("../assets/img/chat_files/def.png");
      }
    },
    onFileGroup(content) {
      // let url = this.$baseStoragePath + JSON.parse(content).file;
      window.open(content);
    },
    showImg(url) {
      this.$hevueImgPreview(url);
    },
    pageChange(page) {
      this.currentPage = page;
      this.allGroupMembers = [];
      this.showGroupMembers();
    },
    pageChangeSmartSearch(page) {
      this.currentPage = page;
      this.groupMemberList = [];
      this.smartSearch();
    },
    onUserAddListCheckbox(item) {
      if (item.selected === undefined) {
        item.selected = true;
      }
      if (item.selected) {
        //add user
        const identifier = item.number == null || item.number == undefined ? item.account : item.number;
        this.addUserToGroup(identifier, 0, 0, "", item);
      } else {
        //remove user
        const identifier = item.number == null || item.number == undefined ? item.account : item.number;

        this.removeUserToGroup(identifier, item);
      }
    },
    addUserToGroup(account, allSelected = 0, agentId = 0, keyWord = "", item = null) {

      let that = this;
      that
        .$http({
          url: `${that.$baseURL}app/GroupChat/addMember`,
          method: "post",
          headers: {

          },
          data: {
            ids: account,
            groupId: that.selectedGroup.id,
            all_members: allSelected == 1,
            key: allSelected == 1 ? that.query : '',
            agent: allSelected == 1 ? (that.selectedAgent == 0 ? '' : that.selectedAgent) : ''
            // members: account,
            // allSelected: allSelected,
            // agentId: agentId,
            // keyWord: keyWord,
          },
        })
        .then((res) => {
          let data = res.data;
          console.log(res);
          if (data.status == 403) {
            if (item) {
              item.selected = false;
            }

            this.$bvModal.msgBoxOk(data.msg, {
              title: "失败的",
              size: "sm",
              buttonSize: "sm",
              okVariant: "primary",
              okTitle: "确认",
              headerClass: "p-2 border-bottom-0",
              footerClass: "p-2 border-top-0",
              centered: true,
            });
          } if (data.status == 200) {
            if (item) {
              item.selected = true;
            }
          }

        }).catch((e)=>{
          console.error(e.message);
        });
    },
    removeUserToGroup(account, item) {
      
      // console.log("Remove user from group API: ", this.groupMemberList)
      let that = this;
      that
        .$http({
          url: `${that.$baseURL}app/GroupChat/removeMember`,
          method: "post",
          headers: {

          },
          data: {
            id: account,
            groupId: that.selectedGroup.id,
            all_members: false
          },
        })
        .then((res) => {
          let data = res.data;
          if (data.status == 200) {
            this.allGroupMembers.splice(this.allGroupMembers.findIndex(e => e.id === item.id), 1);

            this.$bvModal.msgBoxOk("成功移除", {
              title: "成功",
              size: "sm",
              buttonSize: "sm",
              okVariant: "primary",
              okTitle: '确认',
              headerClass: "p-2 border-bottom-0",
              footerClass: "p-2 border-top-0",
              centered: true,
            });
          } else if (data.status == 401) {
            eventBus.$emit("logout");
            this.$router.push({ name: "Login" });
          } else if (data.status == 403) {
            // item.selected = false;
            this.$bvModal.msgBoxOk(data.msg, {
              title: "失败的",
              size: "sm",
              buttonSize: "sm",
              okVariant: "primary",
              okTitle: "确认",
              headerClass: "p-2 border-bottom-0",
              footerClass: "p-2 border-top-0",
              centered: true,
            });
          }
        }).catch((e)=>{
          console.error(e.message);
        });
    },
    sendMessageGroup(format = 0, content = null) {
      switch (format) {
        case 0:
          this.sendMessageApiGroup(content, format);
          break;
        case 1:
          this.sendMessageApiGroup(JSON.stringify(content), format);
          break;
        case 3:
          this.sendMessageApiGroup(JSON.stringify(content), format);
          break;
        case 8:
          this.sendMessageApiGroup(JSON.stringify(content), format);
          break;
        default:
          break;
      }
      this.inputMessage = "";
    },
    addMaxMemberExcededMsg() {
      let that = this;
      let data = {
        content: this.content,
        action: 0,
        format: this.format,
      };
      this.isShowLoader = true;
      that.$http({
        url: "api/message/group/send/" + that.selectedGroup.id,
        method: "post",
        headers: {
          "access-token": that.$store.state.token,
        },
        data: data,
      })
        .then((res) => {
          this.isShowLoader = false;
          if (res.data.code == 200) {
            that.groupMessageContent = "";
            this.$bvModal.msgBoxOk("成功发送信息", {
              title: "成功",
              size: "sm",
              buttonSize: "sm",
              okVariant: "primary",
              okTitle: "确认",
              headerClass: "p-2 border-bottom-0",
              footerClass: "p-2 border-top-0",
              centered: true,
            });
          }
        }).catch((e)=>{
          console.error(e.message);
        })
        .finally(() => {
          this.isShowLoader = false;
        });
    },
    sendMessageApiGroup(content, format) {
      let that = this;
      this.content = content;
      this.format = format;
      if (content.trim() == "" || content.length <= 1) {
        this.emptyMessageError = true;
        return;
      }

      that.$http({
        url: `${that.$baseURL}app/GroupChat/send`,
        method: "post",
        headers: {

        },
        data: {
          host: that.$store.state.number,
          message: content,
          groupId: that.selectedGroup.id,
          type: format
        },
      })
        .then((res) => {
          let data = res.data;
          if (data.status == 200) {
            that.groupMessageContent = "";
            this.$bvModal.msgBoxOk("成功发送信息", {
              title: "成功",
              size: "sm",
              buttonSize: "sm",
              okVariant: "primary",
              okTitle: "确认",
              headerClass: "p-2 border-bottom-0",
              footerClass: "p-2 border-top-0",
              centered: true,
            });
            that.getUserHistory();
          } else if (data.status == 401) {
            eventBus.$emit("logout");
            this.$router.push({ name: "Login" });
          } else {
            // that.makeToast('danger', data.msgId);
            this.$vToastify.error({
              title: "新信息",
              body: data.msg,
              type: "success",
              canTimeout: true,
              duration: 5000,
              position: "top-right",
            });
          }
          // let data = res.data;                
          // if (data.code == 200) {
          //   if (data.data.hasMemberCountExceeded) {
          //       this.modalShowExceededMsg = true;
          //   }else{
          //     let data = {
          //     content: content,
          //     action: 0,
          //     format: format,
          //   };
          //   this.isShowLoader = true;
          //   that.$http({
          //       url: "api/message/group/send/" + that.selectedGroup.id,
          //       method: "post",
          //       headers: {
          //         "access-token": that.$store.state.token,
          //       },
          //       data: data,
          //     })
          //     .then((res) => {
          //       this.isShowLoader = false;          
          //       if (res.data.code == 200) {
          //         that.groupMessageContent = "";
          //         this.$bvModal.msgBoxOk("成功发送信息", {
          //           title: "成功",
          //           size: "sm",
          //           buttonSize: "sm",
          //           okVariant: "primary",
          //           okTitle: "确认",
          //           headerClass: "p-2 border-bottom-0",
          //           footerClass: "p-2 border-top-0",
          //           centered: true,
          //         });
          //       }
          //     })
          //     .finally(() => {
          //       this.isShowLoader = false;
          //     });
          //   }
          // }
        }).catch((e)=>{
          console.error(e.message);
        })
        .finally(() => {
          this.isShowLoader = false;
        });
    },
    getUserHistory(initialLoad = true) {
      let that = this;
      that
        .$http({
          url: that.$baseURL + "/app/Chat/getHistory",
          method: "post",
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            user_id: that.$store.state.number
          }
          ,
        })
        .then((res) => {
          if (res.data.status == 200) {
            let data = res.data;
            data.data.forEach((element) => {
              this.$store.state.message_list.forEach((e) => {
                if (e.number === element.receiver || e.number === element.sender) {
                  const messagePresent = e.agentMessageList.find(message => message.order_id == element.order_id);
                  if (!messagePresent) {
                    e.agentMessageList.push(element)
                  }
                }
              })
            })
            // let lastUpdatedMsg = data.data[data.data.length - 1]
            // if (initialLoad) {
            //   const reversedData = data.data.reverse();
            //   if (reversedData.length) {
            //     reversedData.every(item => {
            //       if (item.is_read == 1) {

            //         this.$store.commit("setLastUpdatedOrderId", item.order_id)
            //         this.$store.commit("setLastReceiver", item.receiver)

            //         return false;
            //       } else {
            //         this.$store.commit("setLastUpdatedOrderId", '')
            //         this.$store.commit("setLastReceiver", '')
            //         return true;
            //       }
            //     })
            //   } else {
            //     this.$store.commit("setLastUpdatedOrderId", '')
            //     this.$store.commit("setLastReceiver", '')
            //   }
            // }
          } else if (res.data.status == 401) {
            this.$router.push({ name: "Login" });
          }

        }).catch((e)=>{
          console.error(e.message);
        });
    },
    makeToast(variant = null, message = '') {
      this.$bvToast.toast(message, {
        title: ``,
        variant: variant,
        solid: true
      })
    },
    chooseImageGroup: function () {
      document.getElementById("fileUploadImageGroup").click();
    },
    onImageUploadGroup(e) {
      this.uploadFileGroup(e.target.files[0], 1);
    },
    uploadFileGroup(file, format) {
      let that = this;
      let formData = new FormData();
      formData.append("file", file);
      // Toast.loading();
      this.isShowLoader = true;
      that
        .$http({
          url: `${that.$baseURL}/app/Chat/Upload/upload`,
          method: "post",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        })
        .then((res) => {
          this.isShowLoader = false;
          if (res.data.status == 200) {
            const uploadURL = res.data.data;
            console.log(format, uploadURL);
            // that.sendMessage(format, uploadURL);
            that.sendMessageApiGroup(uploadURL, format)
          }
        }).catch((e)=>{
          console.error(e.message);
        })
        .finally(() => {
          this.isShowLoader = false;
        });
    },
    // Add all members to the group
    selectAllMembers() {
      this.selectAllModal = true;
    },
    // Remove all group members
    removeAllGroupMembers() {
      let that = this;
      that
        .$http({
          url: `${that.$baseURL}app/GroupChat/removeMember`,
          method: "post",
          headers: {

          },
          data: {
            id: '',
            groupId: that.selectedGroup.id,
            all_members: true
          },
        })
        .then((res) => {
          let data = res.data;
          if (data.status == 200) {
            that.showGroupMembers();
            this.$bvModal.msgBoxOk("成功移除", {
              title: "成功",
              size: "sm",
              buttonSize: "sm",
              okVariant: "primary",
              okTitle: "确认",
              headerClass: "p-2 border-bottom-0",
              footerClass: "p-2 border-top-0",
              centered: true,
            });
            this.$bvModal.hide("modalRemoveAll");
          } else if (data.status == 401) {
            eventBus.$emit("logout");
            this.$router.push({ name: "Login" });
          }
        }).catch((e)=>{
          console.error(e.message);
        });
    },
    chooseVideoGroup: function () {
      document.getElementById("fileUploadVideoGroup").click();
    },
    onVideoUploadGroup(e) {
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      // this.uploadApiFileGroup(formData, 8);
      this.uploadFileGroup(e.target.files[0], 8);
    },
    uploadApiFileGroup(formData, format) {
      let that = this;
      this.isShowLoader = true;
      that
        .$http({
          url: "/api/file/file",
          method: "post",
          headers: {
            "access-token": that.$store.state.token,
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        })
        .then((res) => {
          this.isShowLoader = false;
          var data = res.data;
          var content = {};
          if (data.code == 200) {
            switch (format) {
              case 8:
                content = {
                  duration: 2,
                  image: "73859a7c9b404f16b4c818b0d2983a9c.jpg",
                  mode: 1,
                  size: "5",
                  video: data.data.file,
                };
                that.sendMessageGroup(8, content);
                break;
              case 3:
                content = {
                  file: data.data.file,
                  name: data.data.original,
                  size: "24280",
                };
                that.sendMessageGroup(3, content);
                break;
              default:
                break;
            }
          } else {
            this.isShowLoader = false;
          }
        }).catch((e)=>{
          console.error(e.message);
        })
        .finally(() => {
          this.isShowLoader = false;
        });
    },
    chooseFileGroup: function () {
      document.getElementById("fileUploadFileGroup").click();
    },
    onFileUploadGroup(e) {
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      // this.uploadApiFileGroup(formData, 3);
      this.uploadFileGroup(e.target.files[0], 3);
    },
    onMemberListSearch() {
      this.showGroupMembers(false);
      // let that = this;
      // this.isShowLoader = true;
      // that
      //   .$http({
      //     url:
      //       "/api/group/member/list/" +
      //       this.selectedGroup.id +
      //       "/" +
      //       (that.currentPage - 1), //current page minus 1 because framework paginate expecting first page to be 1, not 0
      //     method: "post",
      //     headers: {
      //       "access-token": that.$store.state.token,
      //     },
      //     data: {
      //       keyword: that.memberListQuery,
      //     },
      //   })
      //   .then((res) => {
      //     if (res.data.code == 200) {
      //       this.isShowLoader = false;
      //       that.allGroupMembers = res.data.dataList;
      //       that.allGroupMembers.forEach((i) => {
      //         i.selected = true;
      //       });
      //       // that.showGroupMembersModal = true;
      //       that.total_rows = res.data.page.count;
      //     } else {
      //       this.isShowLoader = false;
      //     }
      //   })
      //   .finally(() => {
      //     this.isShowLoader = false;
      //   });
    },
    processContent(content) {
      let that = this;
      content = content.replace(/face\[([^\s[\]]+?)\]/g, function (face) {
        //转义表情
        var alt = face.replace(/^face/g, "");
        var imgPath = that.emojiIcons.find((o) => alt === o.key);
        return (
          '<img alt="' +
          alt +
          '" title="' +
          alt +
          '" src="' +
          require(`../assets/img/emoji_files/${imgPath.id}.png`) +
          '">'
        );
      });
      return content;
    },
  },
  watch: {
    selectAll: function (val) {
      if (val) {
        this.addMemeberToGroup();
        //TODO when click select all button handle function
      }
    },
    addGroupMembersModal: function (val) {
      if (!val) {
        this.allAgentList = [];
        this.query = '';
        this.selectedAgent = 0;
        this.currentPage = 1;
      }
    },
    showGroupMembersModal: function (val) {
      if (!val) {
        this.memberListQuery = ''
      }
    }
  },
  mounted: function () {
    this.groupMemberList = [];
    this.loadAllGroups();
    // this.getAgentList();
  },
};
</script>

<style scoped>
.chatWindowBody {
  height: auto;
  min-height: calc(100% - 104px);
  max-height: calc(100% - 104px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.chatWindowBody::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.chatWindowBody ul li {
  list-style: none;
}

.chatWindowBodyHistory ul li {
  list-style: none;
}

.pannelBackground {
  background: url(../assets/img/pattern.png) repeat,
    linear-gradient(#e1e4e7, #f3f4f5);
  height: 100%;
}

.chatGroupname {
  text-align: left;
  margin: 0;
  line-height: 50px;
  color: #ffffff;
  font-weight: bold;
}

.talktext {
  display: inline-block;
  position: relative;
  width: auto;
  height: auto;
  background-color: #435f7a;
  padding: 10px 15px;
  border-radius: 20px;
  color: #ffffff;
}

.talktext p {
  margin: 0px;
  max-width: 600px;
  text-align: justify;
}

.sent {
  margin: 15px 15px 5px 15px;
  width: calc(100% - 25px);
  display: inline-block;
  text-align: right;
}

.sent .textStyle {
  text-align: right;
}

.sent .fileName {
  text-align: right;
}

.imageright {
  width: 30px;
  border-radius: 50%;
  float: right;
  margin: 6px 0 0 8px;
  height: 30px;
}

.message_input {
  height: 50px;
  /* position: absolute;
  bottom: 0; */
  width: 100%;
  z-index: 99;
}

.emojiDesign {
  background-color: #fff;
  box-shadow: 0 0 20px rgb(0 0 0 / 20%);
  margin: 0;
  padding: 7px 14px;
  height: 150px;
  width: 234px;
}

.emojiListDesign {
  cursor: pointer;
  float: left;
  border: 1px solid #e8e8e8;
  overflow: hidden;
  list-style: none;
  padding: 5px;
}

.popoverEmoji .popover-body {
  padding: 0px;
}

.chatHistory {
  text-align: center;
}
</style>
