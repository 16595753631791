<template>
  <b-container
    fluid
    style="
      background-image: url('../assets/img/pattern.png');
          repeat,
        linear-gradient(#e1e4e7, #f3f4f5);
      height: 100vh;
    "
  >
    <b-row class="justify-content-md-center">
      <b-col col lg="2"></b-col>
      <b-col cols="12" md="auto">
        <b-card-group
          style="
            margin: 100px auto;
            width: 400px;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          "
        >
          <b-card header-class="headerStyle" footer-class="footerStyle">
            <template #header>
              <img
                :src="imageUrl"
                class="imageLogo"
              >
              <p style="font-size: 18px">Web客户端登录</p>
            </template>
            <b-card-text>
              <b-input-group size="lg" class="mb-2" style="padding: 12px 0px">
                <b-input-group-prepend is-text>
                  <b-icon icon="person-fill"></b-icon>
                </b-input-group-prepend>
                <b-form-input
                  type="text"
                  placeholder="帐号"
                  style="font-size: 14px; height: 50px"
                  v-model="account"
                ></b-form-input>
              </b-input-group>
              <b-input-group size="lg" class="mb-2" style="padding-bottom: 6px">
                <b-input-group-prepend is-text>
                  <b-icon icon="lock-fill"></b-icon>
                </b-input-group-prepend>
                <b-form-input
                  type="password"
                  placeholder="密码"
                  style="font-size: 14px; height: 50px"
                  v-model="password"
                ></b-form-input>
              </b-input-group>
              <b-alert
                :show="showError"
                variant="danger"
                dismissible
                @dismissed="showError = false"
                fade
                >{{ errorText }}</b-alert
              >
            </b-card-text>
            <template #footer>
              <b-button
                block
                variant="success"
                style="padding: 12px 10px"
                @click="onLogin"
                >登录</b-button
              >
            </template>
          </b-card>
        </b-card-group>
      </b-col>
      <b-col col lg="2"></b-col>
    </b-row>
    <div class="version_text">
      版本号 :  {{ version }}
    </div>
  </b-container>
</template>

<script>
import md5 from "md5";
import { version } from '../../package.json'
export default {
  name: "Login",
  data() {
    return {
      account: "",
      password: "",
      showError: false,
      errorText: "",
      imageUrl:"",
      version:version
    };
  },
  mounted() {
			// this.updateHeaderLine();
 			this.getLoginImage();
      this.getFaviconImg();
		},
  methods: {
    onLogin() {
      let that = this;
      that
        .$http({
          url:that.$baseURL + "app/Chat/CustomerAgent/login",
          method: "post",
          data: {
            number: that.account,
            password: that.password,
          },
        })
        .then((res) => {
          let data = res.data;
          if (data.status == 200) {
            // if (localStorage.account !== that.account) {
              // that.$store.commit("clearMessageCache");
            // }
            if(that.$store.state.number != data.data.number) {
              that.$store.commit("clearAllMessages");
              // that.$store.state.state.loginUserName = "";
              that.getAgentList();
            }
            that.$store.commit("setNumber", data.data.number);
            that.$store.commit("setToken", data.data.token);
           
            sessionStorage.setItem('partner_id', '');
            sessionStorage.setItem('partner_id', data.data.partner_id);
            
            // that.getFaviconImg();
            that.$vToastify.success({
              title: "Success",
              body: data.msg,
              type: "success",
              canTimeout: true,
              duration: 3000,
              position: "top",
            });

            that.$router.push({ name: "ChatWindow" });
            // window.location.reload();
            
          } else {
            that.showError = true;
            that.errorText = "手机号码或登录密码错误";
          }
        }).catch((e)=>{
          console.error(e.message);
        });
    },
    getLoginImage() {
    console.log("get login image");
	console.log(this.$route.query.token);
    let context = this;
    context.$http({
        url: context.$baseURL + "app/image/getImage/" + context.$route.query.token +"?key=admin.basic.site.logo",
        method: 'get',
    }, (errorMsg) => {
        this.showToast({
            type: 'error',
            message: errorMsg,
        });
    }).then(res => {
        context.imageUrl = res.data.data;
        console.log(context.imageUrl);
    });
},
getFaviconImg() {
      var url = this.$route.fullPath;
      var token = ''
      var tokenIndex = url.indexOf("token=");
        if (tokenIndex !== -1) {
          token = url.substring(tokenIndex + 6);
          sessionStorage.setItem('partner_id', token);
        }

      this
        .$http({
          url: this.$baseURL + `app/image/getTitleData/?token=${token}&keyForLogo=all.favicon.icon&keyForTitle=admin.basic.site.platformName`,
          method: "get",
          headers: {
            'PartnerId': sessionStorage.getItem("partner_id") ? sessionStorage.getItem("partner_id") : ''
          },
        })
        .then(function (res) {
          if (res.data.status == 200) {
            sessionStorage.setItem("faviconImg", res.data.data.imageUrl)
          }
        });
    },
    getAgentList() {
      let that = this;
      that
        .$http({
          url: that.$baseURL + "/app/Chat/agentList",
          method: "post",
          headers: {
            'Content-Type': 'application/json'
          },
          data: {},
        })
        .then((res) => {
          if (res.data.status == 200) {
            let data = res.data;
            let loggedUserDetails = data.data.filter((e) => { return e.number === that.$store.state.number })
            this.$store.commit("setLoggedUserName", loggedUserDetails[0].name)
            console.log('setLoggedUserName', this.$store.state.loginUserName);
            if (this.$store.state.message_list.length > 0) {
              const agents = [];
              data.data.map((apiAgent) => {
                const agentAvailable = this.$store.state.message_list.find(availableAgent => availableAgent.number == apiAgent.number);
                if (agentAvailable == undefined) {
                  agents.push(apiAgent);
                }
              });

              let allAgents = [...agents, ...this.$store.state.message_list]
              //remove own agent and unnecessary values
              allAgents = allAgents.filter(function (element) {
                return element !== undefined && element.number != that.$store.state.number;
              });

              this.$store.commit("clearAgentList");

              //Remove agents that were disabled by the admin
              allAgents.forEach(item => {
                const availableInAPIList = data.data.find(agent => agent.number ===
                  item.number);
                if (!availableInAPIList) {
                  const removalIndex = allAgents.findIndex((element) => element.number == item.number);
                  allAgents.splice(removalIndex, 1)
                }
              })


              this.$store.commit("setAgentList", allAgents)
            } else {
              //remove our user from list
              const filtered = data.data.filter((agent) => agent.number != that.$store.state.number)

              this.$store.commit("setAgentList", filtered)
              //  this.$store.commit("setAgentList", data.data)
            }
            // if(this.$store.state.loginUserName != loggedUserDetails[0].name) {
              this.getRecentUserList()
            // }
            // this.getUserHistory()
          } else if (res.data.status == 401) {
            this.$router.push({ name: "Login" });
          }
        }).catch((e) => {
          console.error(e.message);
        });
    },
    getRecentUserList() {
      let that = this;
      that
        .$http({
          url: that.$baseURL + "/app/Chat/CustomerAgent/online",
          method: "post",
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            user_id: that.$store.state.number
          }
        }).then(res => {
          if (res.data.status == 200) {
            let users = res.data.data;
            let newUsers = [];
            const storedAgents = that.$store.state.message_list;

            if (users.length > 0) {
              users.forEach(user => {
                const userInAgent = storedAgents.find(item => item.number == user.number)

                // If user is not in the current list
                if (!userInAgent) {
                  const isUserAdded = newUsers.find(item => item.number == user.number)
                  // Add user to the array only if user not already in the array
                  if (!isUserAdded) {
                    newUsers.push(user);
                  }
                } else {
                  newUsers.push(userInAgent)
                }
              })

              // Push the agents from agentlist array to the new array
              storedAgents.forEach(storeAgent => {
                const isInNewUsers = newUsers.find(item => item.number == storeAgent.number)
                console.log(isInNewUsers);
                if (!isInNewUsers) {
                  newUsers.push(storeAgent)
                }
              })
            } else {
              newUsers = storedAgents
            }
            
            // const newTotalUsers = [...newUsers, ...that.$store.state.message_list]
            this.$store.commit("clearAgentList");
            this.$store.commit("setAgentList", newUsers)

            this.getUserHistory()
          }
        })
    },
    getUserHistory(initialLoad = true) {
      let that = this;
      that
        .$http({
          url: that.$baseURL + "/app/Chat/getHistory",
          method: "post",
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            user_id: that.$store.state.number
          }
          ,
        })
        .then((res) => {
          if (res.data.status == 200) {
            let data = res.data;
            data.data.forEach((element) => {
              this.$store.state.message_list.forEach((e) => {
                if (e.number === element.receiver || e.number === element.sender) {
                  const messagePresent = e.agentMessageList.find(message => message.order_id == element.order_id);
                  if (!messagePresent) {
                    e.agentMessageList.push(element)
                  }
                }
              })
              if (element.sender == "SYSTEM") {
                this.$store.commit('addNewSystemMessage', element);
              }
            })
            // let lastUpdatedMsg = data.data[data.data.length - 1]
            if (initialLoad) {
              // Check if agent is new (only has system messages)
              // And set the last msg id of the system message
              const isNewAgent = data.data.every(item => item.receiver === 'SYSTEM')
              if (isNewAgent) {
                const reversedData = data.data.reverse();
                const orderId = reversedData[0].order_id
                const receiver = reversedData[0].receiver

                this.$store.commit("setLastUpdatedOrderId", orderId)
                this.$store.commit("setLastReceiver", receiver)
              } else {
                const reversedData = data.data.reverse();
                if (reversedData.length) {
                  reversedData.every(item => {

                    const ifFirstLogin = that.$store.state.lastOrderId == '';

                    let isSystemValidation = true;

                    if (ifFirstLogin) {
                      isSystemValidation = item.receiver != 'SYSTEM';
                    } else {
                      isSystemValidation = true;
                    }

                    if (item.is_read == 1 && isSystemValidation) {

                      this.$store.commit("setLastUpdatedOrderId", item.order_id)
                      this.$store.commit("setLastReceiver", item.receiver)

                      return false;
                    } else {
                      this.$store.commit("setLastUpdatedOrderId", item.order_id)
                      this.$store.commit("setLastReceiver", item.receiver)
                      return true;
                    }
                  })
                } else {
                  this.$store.commit("setLastUpdatedOrderId", '')
                  this.$store.commit("setLastReceiver", '')
                }
              }
            }

            // this.$store.commit("setLastUpdatedOrderId", lastUpdatedMsg.order_id)
            // this.$store.commit("setLastReceiver", lastUpdatedMsg.receiver)
            if (initialLoad) {
              this.getNewlyMsg(true);
              this.getOfflineMsg();
              setInterval(() => {
                this.getNewlyMsg(false);
              }, 3000);
              setInterval(() => {
                this.getOfflineMsg();
              }, 60000);
            }
          } else if (res.data.status == 401) {
            this.$router.push({ name: "Login" });
          }

        }).catch((e) => {
          console.error(e.message);
        });
    },
    getNewlyMsg(isInitial) {
      let that = this;
      //Recieve new message API
      that
        .$http({
          url: that.$baseURL + "app/Chat/get",
          method: "post",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': that.$store.state.token
          },
          data: {
            user_id: that.$store.state.number,
            receiver: that.$store.state.lastReceiver,
            order_id: that.$store.state.lastOrderId
          }
          ,
        })
        .then((res) => {
          let data = res.data;
          if (data.status == 200) {
            that.handleSystemMessage(res.data.data.systemMessages);
            data.data.msgList.map((element) => {
              const availableSender = this.$store.state.message_list.find(agent => agent.number == element.sender);

              const messageData = {
                count: null,
                create_time: element.create_time,
                id: Date.now(),
                is_read: element.is_read,
                message: element.message,
                msgId: element.msgId,
                order_id: element.order_id,
                receiver: element.receiver,
                sender: element.sender,
                time: element.time,
                type: element.type,
                user_id: element.user_id,
                sender_account: element.sender_account,
                status: 1
              }


              that.playSound();
              //check if the current opened chat is same as the new message user 
              //if so just add to the list and show on UI
              //otherwise add to the store
              if (availableSender == undefined) {
                //new user
                this.$store.commit('addNewAgent', {
                  id: Date.now(),
                  agentMessageList: [],
                  count: 0,
                  name: element.sender_name,
                  real_name: element.sender_name,
                  number: element.sender,
                  account:element.sender_account
                })
                that.getUserHistory(false);
                const agent = that.$store.state.message_list.findIndex(agent => agent.number == element.sender);
                this.$store.commit('updateUnreadCount', {
                  index: agent,
                  reset: false
                  // count: allUnreadCount[property]
                })

                this.$store.commit('setLastUpdatedOrderId', element.order_id);
                this.$store.commit("setLastReceiver", element.receiver)

              } else {
                //existing user
                //Add new message to the list
                this.$store.commit('addNewMessageByAgentNumber', {
                  number: element.sender,
                  message: messageData
                })
                //Update last message
                this.$store.commit('setLastUpdatedOrderId', element.order_id);
                this.$store.commit("setLastReceiver", element.receiver)
                const agent = that.$store.state.message_list.findIndex(agent => agent.number == element.sender);
                this.$store.commit('updateAgentName', {
                  index: agent,
                  username: element.sender_name ? element.sender_name : availableSender.name
                })

                if (this.selectedUser.number == element.sender) {
                  //Chat currently opened

                  this.getReadMsgs(this.selectedUser.number);

                } else if (!isInitial) {
                  //Chat currently closed
                  const allUnreadCount = data.data.count;
                  for (const property in allUnreadCount) {
                    // const agent = that.$store.state.message_list.findIndex(agent=>agent.number == property);
                    if (agent != -1) {
                      this.$store.commit('updateUnreadCount', {
                        index: agent,
                        reset: false
                        // count: allUnreadCount[property]
                      })
                    }
                  }

                  // console.log(agent);
                }

              }
              setTimeout(() => {
                $(".chatWindowBody").animate(
                  { scrollTop: $("#chatWindowBodyUl").prop("scrollHeight") },
                  500
                );
              }, 500);
            });
            if (data.data.msgList.length) {
              this.checkStatusOfMsgs()
            }
            
            this.getMsgCountDisplay(data.data.count)
          } else if (data.status == 401) {
            this.$router.push({ name: "Login" });
            // this.makeToast('danger', 'Messages API failed');
          } else {
          }
        }).catch((e) => {
          console.error(e.message);
        });
    },
    getOfflineMsg() {
      let that = this;
      //Recieve new message API
      that
        .$http({
          url: that.$baseURL + "app/Chat/getOfflineMsg",
          method: "post",
          headers: {
            'Content-Type': 'application/json',
            'Authorization': that.$store.state.token
          },
          data: {
            user_id: that.$store.state.number,
            receiver: that.$store.state.lastReceiver,
            order_id: that.$store.state.lastOrderId
          }
          ,
        })
        .then((res) => {
          let data = res.data;
          if (data.status == 200) {
            that.handleSystemMessage(res.data.data.systemMessages);
            data.data.msgList.map((element) => {
              const availableSender = this.$store.state.message_list.find(agent => agent.number == element.sender);

              const messageData = {
                count: null,
                create_time: element.create_time,
                id: Date.now(),
                is_read: element.is_read,
                message: element.message,
                msgId: element.msgId,
                order_id: element.order_id,
                receiver: element.receiver,
                sender: element.sender,
                time: element.time,
                type: element.type,
                user_id: element.user_id
              }


              that.playSound();
              //check if the current opened chat is same as the new message user 
              //if so just add to the list and show on UI
              //otherwise add to the store
              if (availableSender == undefined) {
                //new user
                this.$store.commit('addNewAgent', {
                  id: Date.now(),
                  agentMessageList: [],
                  count: 0,
                  name: element.sender_name,
                  number: element.sender,
                  account:element.sender_account
                })
                that.getUserHistory(false);
                const agent = that.$store.state.message_list.findIndex(agent => agent.number == element.sender);
                this.$store.commit('updateUnreadCount', {
                  index: agent,
                  reset: false
                  // count: allUnreadCount[property]
                })

                this.$store.commit('setLastUpdatedOrderId', element.order_id);
                this.$store.commit("setLastReceiver", element.receiver)

              } else {
                //existing user
                //Add new message to the list
                this.$store.commit('addNewMessageByAgentNumber', {
                  number: element.sender,
                  message: messageData
                })
                //Update last message
                this.$store.commit('setLastUpdatedOrderId', element.order_id);
                this.$store.commit("setLastReceiver", element.receiver)
                const agent = that.$store.state.message_list.findIndex(agent => agent.number == element.sender);
                this.$store.commit('updateAgentName', {
                  index: agent,
                  username: element.sender_name
                })

                if (this.selectedUser.number == element.sender) {
                  //Chat currently opened

                  this.getReadMsgs(this.selectedUser.number);

                } else {
                  //Chat currently closed
                  const allUnreadCount = data.data.count;
                  for (const property in allUnreadCount) {
                    if (agent != -1) {
                      this.$store.commit('updateUnreadCount', {
                        index: agent,
                        reset: false
                        // count: allUnreadCount[property]
                      })
                    }
                  }

                  // console.log(agent);
                }

              }
              setTimeout(() => {
                $(".chatWindowBody").animate(
                  { scrollTop: $("#chatWindowBodyUl").prop("scrollHeight") },
                  500
                );
              }, 500);
            });
          } else if (data.status == 401) {
            this.$router.push({ name: "Login" });
            // this.makeToast('danger', 'Messages API failed');
          } else {
            console.error("Message getting API failed...");
          }
        }).catch((e) => {
          console.error(e.message);
        });
    },
    handleSystemMessage(systemMessage) {
      if (systemMessage.msgList.length > 0) {
        this.playSound();

        systemMessage.msgList.forEach((message) => {
          //chat currently opened
          this.$store.commit('addNewSystemMessage', message);
          this.$store.commit('setLastUpdatedOrderId', message.order_id);
          this.$store.commit("setLastReceiver", message.receiver)
          if (this.selectedUser.sender == 'SYSTEM') {
            //Add to store 
            //should update since its assigned

          } else {
            //Increase notification count
            this.$store.commit('updateSystemMessageCount', false);
          }
          //Update last message ID and reciever
          //Add to system message array
          //Increase notification count if not in chat
          //If inside chat push to chat list 
        })
      }
    },
    getMsgCountDisplay(obj){
      if(Object.keys(obj).length !== 0) {
        this.$store.state.message_list.forEach((e) =>{
          Object.keys(obj).forEach((el) =>{
            if(e.id ==  el){
            this.$set(e, 'newcount', obj[Object.keys(obj)])
            this.$store.commit("setAgentList", e)
          }
          })
        })
      }
    },
    getReadMsgs(senderId) {
      let that = this;
      that
        .$http({
          url: that.$baseURL + "/app/Chat/readMsg",
          method: "post",
          headers: {
            'Content-Type': 'application/json'
          },
          data: {
            user_id: that.$store.state.number,
            sender: senderId
          }
        })
        .then((res) => {
          if (res.data.status == 401) {
            this.$router.push({ name: "Login" });
          }
        }).catch((e) => {
          console.error(e.message);
        })
    },
  },
};
</script>

<style scoped>
.headerStyle {
  background: #ffffff;
}

.footerStyle {
  background: #ffffff;
  padding: 20px 50px;
}

.btn-success {
  background-color: #28a745;
}

.imageLogo {
  border-radius: 50%;
  width: 100px;
  margin: 30px 0px;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
    rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}
.version_text {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: max-content; /* Adjust the width as needed */
  text-align: center;
  color: #566573;
  font-size: 12px;
}
</style>